import React from 'react'
import Speak from '../../components/Speak';
import Pricing from './Pricing';
import Header from "./Header";
import Cards2 from "./Cards2";
import Cards1 from "./Cards1";
import data from "./../../data";
import VonageCommunication from './VonageCommunication';
import WhatAPI from './WhatAPI';

function Index({location}) {
    return (
        <div>
            <Header />
            <WhatAPI />
            <Cards1 card={data.card} />
            <VonageCommunication />
            <Cards2 card2={data.card2} />
            <Pricing />
            <Speak  location={location}/>
        </div>
    )
}

export default Index
