import React from "react";

const Cards = ({ card }) => {
  return (
    <div className="sm:px-8 px-3 py-14 ">
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1  md:mt-16 mt-8 gap-10">
        {card.map((item, index) => {
          return (
            <div className="px-8">
              <Card
                index={index}
                name={item.name}
                link={item.link}
                desc={item.desc}
                src={item.src}
                btn={item.btn}
                // icons={item.icons}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Cards;
const Card = ({ name, link, desc, src, btn, icons }) => {
  return (
    <div>
      {" "}
      {/* <Icon>{icons}</Icon> */}
      <h1 className="text-lg mb-8 font-medium">{name}</h1>
      <p className="text-base mb-12 font-normal">{desc}</p>
      <div className="flex justify-between sm:flex-nowrap flex-wrap">
        {" "}
        <a
          href={src}
          className="sm:mb-0 mb-10 text-sm font-semibold tracking-widest border-b-2 border-black"
        >
          LEARN MORE
        </a>
        <a href={link}>
          <span className="font-semibold text-base bg-black text-white  px-6 py-6 rounded-lg">
            {btn}
          </span>
        </a>
      </div>
    </div>
  );
};
