import React from "react";

function WhatAPI() {
  return (
    <div>
      <div className="px-8 pt-10 m-auto max-w-7xl md:pt-20">
        <div className="flex items-center justify-center">
          <div className="flex flex-wrap items-center justify-center">
            <div >
              <h1 className="mb-5 text-4xl font-medium text-center md:mr-4">
                What API are you interested in?
              </h1>
            </div>
            <div className="flex">
              {/* <div className="flex-1 p-4 bg-white border-2 rounded-full outline-none  md:w-64 w-52 border-grey-normal"> */}
              <select className="flex-1 p-4 bg-white border-2 rounded-full outline-none focus:outline-none md:w-64 w-52 border-grey-normal">
                <option value="1">Select</option>
                <option value="2">1</option>
                <option value="3">2</option>
              </select>
              {/* </div> */}
              <div className="self-center ml-4">
                <a
                  href="/"
                  className="px-5 py-4 text-base font-semibold text-white bg-black rounded-lg"
                >
                  Go
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatAPI;
