import React from "react";
import ComuniImg from "./../../images/3u-products.png";

function Header() {
  return (
    <div>
      {" "}
      <div className="header-gradient">
        <div className="max-w-7xl m-auto md:py-24 py-12 px-8">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-10">
            <div>
              <h1 className=" font-medium text-white md:text-5xl text-4xl mb-4">
              Communications APIs: Be there, build that
              </h1>
              <p className="md:text-xl text-lg font-normal text-white mb-16">
              To embrace your role as a customer experience innovator and problem solver, you need the right tools. Start with Vonage APIs to build your unique solution, and delight both your customers and team at scale. Startups and agile enterprises around the world already have. If you can imagine 
              the next-gen customer experience—then you can begin building it here.
              </p>
              <div className="flex flex-wrap md:justify-start justify-center">
                <a
                  href="/"
                  className="bg-white border-white border-2 text-black px-8 mb-6 mr-6 py-6 rounded-lg"
                >
                  Try it Free 
                </a>
                <a
                  href="/"
                  className="border-white border-2 text-white px-8 mb-6 mr-6 py-6 rounded-lg"
                >
                  Talk to Expert
                </a>
              </div>
            </div>
            <div>
              <img src={ComuniImg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
