import React from "react";
import { BsArrowRight } from "react-icons/bs";

function Pricing() {
  return (
    <div>
      <div className="max-w-7xl m-auto md:py-24 py-12 px-8">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-10">
          <div className="bg-black md:py-32 py-24 md:px-20 px-14 text-white info-card">
            <h1 className="mb-4 font-black text-3xl">Start building</h1>
            <p className="text-lg font-normal mb-12">
              Get to work fast with free credit on us. Then prepare for the oohs
              and aahs.
            </p>
            <BsArrowRight className="text-4xl" />
          </div>
          <div className="bg-black md:py-32 py-24 md:px-20 px-14 text-white info-card">
            <h1 className="mb-4 font-black text-3xl">See pricing</h1>
            <p className="text-lg font-normal mb-12">
              Save big by only paying for what you use. Nothing more.
            </p>
            <BsArrowRight className="text-4xl" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
